.pricing-modal {
    max-width: 90%;
    padding: 0;
}

.pricing-modal .ant-modal-content {
    padding: 24px 0;
    border-radius: 24px;
    background-color: rgb(238, 244, 255);
}
