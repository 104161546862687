.preloader {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.preloader:before, .preloader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.preloader:before {
    content: '';
    background-color: #fff;
    opacity: 0;
    z-index: 5;
}

.preloader:after {
    opacity: 0;
    background-repeat: no-repeat;
    background-size: 96px;
    background-position: center;
    background-image: url('./loading.gif');
    z-index: 6;
}

.preloader.is-loading {
    height: 100%;
    width: 100%;
}

.preloader.is-loading:before {
    transition: opacity 0.2s 0.5s;
    opacity: 0.3;
}

.preloader.is-loading:after {
    transition: opacity 0s 0.5s;
    opacity: 1;
}

.spinner {
    --radius: 43.2;
    --size: 21.6;
    position: absolute;
    width: calc(var(--size) * 1px);
    height: calc(var(--size) * 1px);
    content: '';
    left: 50%;
    top: 50%;
    opacity: 0.0;
}

.spinner div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #474bff;
    border-radius: 50%;
    transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 0px));
    animation: spinner-19rk4d 2.0999999999999996s calc(var(--delay) * 1.68s) infinite ease;
}

.spinner div:nth-child(1) {
    --angle: 45;
    --delay: 0.13999999999999999;
}

.spinner div:nth-child(2) {
    --angle: 90;
    --delay: 0.27999999999999997;
}

.spinner div:nth-child(3) {
    --angle: 135;
    --delay: 0.42;
}

.spinner div:nth-child(4) {
    --angle: 180;
    --delay: 0.5599999999999999;
}

.spinner div:nth-child(5) {
    --angle: 225;
    --delay: 0.7;
}

.spinner div:nth-child(6) {
    --angle: 270;
    --delay: 0.84;
}

.spinner div:nth-child(7) {
    --angle: 315;
    --delay: 0.9799999999999999;
}

.spinner div:nth-child(8) {
    --angle: 360;
    --delay: 1.1199999999999999;
}

@keyframes spinner-19rk4d {
    0%, 30%, 50%, 100% {
        transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 0px)) scale(0);
    }

    40% {
        transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(1);
    }
}

.spinner:before, .spinner:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.spinner.is-loading {
    /*height: 100%;*/
    /*width: 100%;*/
    opacity: 1;
}

.spinner.is-loading:before {
    transition: opacity 0.2s 0.5s;
    opacity: 0.3;
}

.spinner.is-loading:after {
    transition: opacity 0s 0.5s;
    opacity: 1;
}
