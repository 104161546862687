.sidebar-wrap {
    position: fixed;
    top: 0;
    left: 0;
    transition: width .3s;
    height: 100vh;
    z-index: 10;
    -moz-box-shadow:    -3px 0 10px 0 #555;
    -webkit-box-shadow: -3px 0 5px 0 #555;
    box-shadow:         -3px 0 5px 0 #555;
}

.sidebar-content {
    height: 100%;
    position: absolute;
    padding: 12px;
    top: 0;
    right: 0;
    background-color: rgb(238, 244, 255);
    border-right: 1px solid #d9d9d9;
    overflow-y: auto;
}

.sidebar-button {
    height: 30px;
    width: 30px;
    position: absolute;
    left: 100%;
    top: calc(50% - 15px);
    border: 1px solid #d9d9d9;
    border-left: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    z-index: 20;
    background-color: #fff;
    font-size: 20px;
    padding: 3px;

}

.sidebar-button > div {
    transform: rotateY(0deg);
    transition: transform 0.2s;
}

.sidebar-button.is-collapsed > div {
    transform: rotateY(180deg);
}

