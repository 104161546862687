.ideas-form-wrap {

}

.ideas-form-wrap .ant-form-item-control-input-content {
    padding-bottom: 0px;
    border-bottom: 0px solid #C7D7FE;
}

.line {
    border-top: 1px solid #C7D7FE;
    padding-bottom: 2px
}

.ideas-form-wrap .idea-from-select {
    padding-bottom: 0px;
    border-bottom: 0px solid #C7D7FE;
}

/* Override padding-left for Collapse header */
.customCollapse .ant-collapse-header {
    padding-left: 0 !important;
}

/* Override padding-left for Collapse content */
.customCollapse .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0 !important;
}

.ideas-form-input {
    /*font-size: 50px;*/
    font-size: 22px;
    line-height: 36px;
    background-color: transparent;
    outline: none;
    border: none;
    text-align: center;
}

@media screen and (min-width: 988px) {
    .ideas-form-input {
        font-size: 50px;
        line-height: 73px;
    }
}

.ideas-form-input:active, .ideas-form-input:focus, .ideas-form-input:hover,
.ideas-form-input.ant-input-status-error:active, .ideas-form-input.ant-input-status-error:focus, .ideas-form-input.ant-input-status-error:hover {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.ant-form-item-explain-error{
    margin-top: 25px;
    font-size: medium;
}

.ideas-form-add-content {
    margin: 0 0 10px 10px;
}

@media screen and (min-width: 988px) {
    .ideas-form-add-content {
        margin: 0 0 10px 50px;
    }
}
