.ant-layout-content.login-content {
    padding-top: 120px;
    align-items: start;
    flex: 1;
}
.login-content {
    min-width: 80vw;
    display: flex;
    justify-content: center;
}

.card-wrap {
    max-width: 592px;
}

.card-container {
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    border-radius: 24px;
}

.card-container .ant-card-body {
    padding: 40px;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header{
    text-align: center;
    color: #fff;
    height: 64px;
    line-height: 64px;
    background-color: #7dbcea;
}

.social-buttons-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.social-buttons-wrap .button-custom {
    width: 100%;
    margin: 12px 0 0;
}

@media screen and (min-width: 768px) {
    .social-buttons-wrap {
        flex-wrap: nowrap;
    }
    .social-buttons-wrap .button-custom {
        margin: 12px 8px 0;
    }
}

.social-buttons-wrap .button-custom:first-child {
    margin-left: 0;
}

.social-buttons-wrap .button-custom:last-child {
    margin-right: 0;
}

.login-or-container {
    width: 100%;
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #98A2B3;
    margin-top: 24px;
    margin-bottom: 12px;
}

.login-or-container:before, .login-or-container:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    margin-top: -1px;
    border-bottom: 1px solid #EAECF0;
}

.login-or-container:before {
    left: 0;
}

.login-or-container:after {
    right: 0;
}

.login-email-container {
    width: 100%;
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: #98A2B3;
    margin-top: 24px;
    margin-bottom: 5px;
    padding:5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-email-container .ant-input {
    width: 100%;
    margin-bottom: 10px;
}

.login-email-container p {
    margin-top: 10px;
}

.login-email-container .ant-btn {
    width: 100%;
}
