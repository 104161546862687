.presentation-wrap {
    position: relative;
}

.presentation-controls {
    margin-top: 40px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6172F3;
    font-size: 18px;
    font-weight: 600;
}

.presentation-controls.read-only {
    margin-bottom: 0px;
}

.presentation-controls-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.presentation-controls-right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.presentation-controls-right > * {
    margin-left: 10px;
    margin-right: 10px;
}

.presentation-controls-right > *:last-child {
    margin-right: 0;
}

.presentation-controls-right .button-custom {
    background-color: #E0EAFF;
    border-color: transparent;
    color: #6172F3;
}

.presentation-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 32px;
    text-align: left;
}

.presentation-title.read-only{
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 32px;
    text-align: left;
    padding-left: 32px;
}

.presentation {
    display: flex;
    background: #FCFCFD;
    border-radius: 16px;
    height: 646px;
}

.presentation.read-only {
    display: flex;
    background: transparent;
    border-radius: 16px;
    height: 646px;
}

.presentation-thumbnails {
    width: 247px;
    padding: 20px 16px 20px 0;
    height: 100%;
    overflow: auto;
    flex-shrink: 0;
}

.presentation-thumbnail-item {
    cursor: pointer;
    width: 100%;
    height: 123px;
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 0;
    border-left: 4px solid transparent;
    margin-bottom: 8px;
    border-radius: 0 12px 12px 0;
    position: relative;
}


.presentation-thumbnail-item.is-active {
    border-left-color: #6172F3;
    background-color: #E0EAFF;
}

.presentation-thumbnail-index {
    width: 35px;
    text-align: center;
}

.presentation-thumbnail-content {
    height: 99px;
    border-radius: 12px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.presentation-slide-content {
    width: calc(100% - 247px);
    height: 100%;
    border-left: 1px solid #D0D5DD;
    padding: 32px 32px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.presentation-slide-content.read-only {
    width: 95%;
    height: 95%;
    border-left: 0px solid #D0D5DD;
    padding: 32px 32px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.presentation-slide-content.is-loading {
    pointer-events: none;
}

.presentation-slide-content__canvas {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}

.canvas-container {
    border-radius: 16px;
    overflow: hidden;
}

.fullscreen-enabled {
    position: relative;
}

.fullscreen-enabled .presentation-slide-content__canvas {
    align-items: center;
    cursor: pointer;
}

.fullscreen-enabled .presentation-slide-content__canvas .canvas-container {
    pointer-events: none;
}
