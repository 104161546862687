.image-crop-wrap {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    height: 1px;
    width: 1px;
    visibility: hidden;
    opacity: 0;
    border-radius: 76% 24% 81% 19% / 30% 78% 22% 70%;
}
