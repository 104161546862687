.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #101828;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.layout-header {
  background-color: transparent;
  padding: 12px 0;
}

.layout-header .row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-container {
  width: 100%;
  max-width: 1256px;
  padding: 0 20px;
  margin: 0 auto;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-footer {
  align-self: stretch;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 10vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item {
  font-weight: 500;
  font-size: 14px;
}

.ant-tooltip .ant-tooltip-inner.ant-tooltip-inner {
  border-radius: 36px;
  padding: 8px 12px;
  margin-top: -5px;
}

.ant-tooltip.ant-tooltip {
  pointer-events: none;
}

.ant-tooltip-arrow {
  visibility: hidden;
}

.page-layout {
}

@media screen and (min-width: 988px) {
  .page-layout {
    padding-top: 60px;
  }
}

.page-column {
  padding-top: 20px;
}

@media screen and (min-width: 988px) {
  .page-content {
    margin: 0 16px;
  }
}
