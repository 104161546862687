.ideas-list-page {

}

.ideas-list-title {
    font-size: 60px;
    line-height: 73px;
    text-align: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #C7D7FE;
    margin-bottom: 70px;
}

.ideas-list-wrap {

}

.ideas-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ideas-list-header__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.ideas-list {
    margin-bottom: 28px;
}

.ideas-list-item {
    background-color: #FCFCFD;
    text-align: left;
    margin-bottom: 16px;
    border-radius: 16px;
    font-size: 20px;
}

.ideas-list .ideas-list-item {
    padding: 20px;
}

.ideas-list-item .ant-checkbox-wrapper {
    margin-right: 12px;
}
