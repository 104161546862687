.typography-controls {
    display: flex;
    padding-bottom: 8px;
}

.typography-controls .button-custom {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    padding: 4px 5px;
    min-width: 48px;
}

.file-input {
    pointer-events: none;
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
}
