.preview-image-wrap {
    position: relative;
    background-color: #FCFCFD;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 16px;
}

.preview-image-wrap:after {
    content: '';
    display: block;
    padding-top: 56.4%;
}

.preview-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.preview-title {
    font-size: 16px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 4px;
}

.preview-updated {
    color: #667085;
    font-weight: 500;
    font-size: 12px;
}
