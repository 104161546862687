.canvas-element-controls {
    position: absolute;
    display: flex;
    z-index: 100;
}

.canvas-element-controls.is-hidden {
    pointer-events: none;
    visibility: hidden;
}
