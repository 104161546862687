.layout-charts {
    position: relative;
    padding-bottom: 20px;
}

.sider-main {
    position: relative;
    padding-bottom: 20px;
}

/* Add a media query for mobile devices */
@media (max-width: 767px) {
    /* Make the Sider collapse on mobile devices */
    .sider-main {
        display: none;
    }

    /* Make the Content fill the entire width on mobile devices */
    .layout-charts .ant-layout-content {
        margin: 0;
    }
}

.ant-tag.ant-tag {
    white-space: normal;
}

.ant-tag {
    max-width: 260px;
    margin: 8px;
}

.ant-tag >.anticon+span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
