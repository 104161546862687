@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');

}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*="ant-"] {
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
